.button {
  display: inline-block;
  padding: 0.66em 1.25em;
  border: 1px solid var(--color-grey-600);
  border-radius: 0.5em;
  font-weight: 700;
  background-color: var(--color-white);
  color: var(--color-grey-200);
  cursor: pointer;
  box-shadow: inset -1px -1px 4px 2px rgba(0, 0, 0, 0.08);
}
.button.--selected {
  background-color: var(--color-grey-400);
  color: #fff;
  box-shadow: inset 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
}

.--dark .button {
  border-color: var(--color-grey-100);
}

.button-group {
  display: inline-block;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

.button-group .button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.button-group .button:not(:first-child) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-group .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
