.switch {
  position: relative;
  height: 1.75em;
  width: 3.5em;
  font-size: 1rem;
  border-radius: 1em;
  background-color: var(--color-grey-800);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

  .switch input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .switch span {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
    display: inline-block;
    height: calc(100% - 6px);
    width: calc(50% - 6px);
    border-radius: 50%;
    background-color: var(--color-grey-400);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3),
                inset -1px -1px 2px rgba(0, 0, 0, 0.2),
                inset 1px 1px 2px rgba(255, 255, 255, 0.6);
  }
  .switch input:checked + span {
    left: auto;
    right: 3px;
  }
