.weekly {
  font-size: 1rem;
}

  .weekly-days {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2em;
    row-gap: 2em;
  }
    .weekly-day {
      position: relative;
      min-width: 7em;
    }
      .weekly-day strong {
        display: block;
        padding: 0.5em 0 1.0em;
        font-size: 1em;
        text-transform: uppercase;
      }
      .weekly-day-temperature {
        display: flex;
      }
      .weekly-day-temperature:not(:first-of-type) {
        margin-top: 0.5em;
      }
        .weekly-day-temperature > span:first-child {
          width: 2em;
        }
        .weekly-day-temperature > span:last-child {
          flex: 1;
          margin-left: 0.5em;
        }
        .weekly-day-temperature .temperature {
          margin-left: 1em;
          text-align: right;
        }

        .weekly-day {
          --pos-x: 0;
          --pos-y: 0;
          --bg-height: 100%;
          --height: 5rem;
          --width: 6px;
        }

        .weekly-day::after {
          display: none;
          content: '';
          position: absolute;
          bottom: 0;
          right: -0;
          width: var(--width);
          height: var(--height);
          border-radius: var(--width);
          background-repeat: no-repeat;
          background-size: var(--width) var(--bg-height);
          background-position: var(--pos-x) var(--pos-y);
          background-image: linear-gradient(to top, 
            rgba(9,57,121,1) 0%,
            rgba(53,194,207,1) 38%,
            rgba(186,195,49,1) 60%,
            rgba(224,172,52,1) 80%,
            rgba(255,109,0,1) 100%);
          opacity: 0.25;
        }
        .weekly-day:hover::after {
          opacity: 1;
        }

.--lcars .weekly-days {
  grid-template-columns: repeat(2, 1fr);
}

  .--lcars .weekly-day {
    min-width: auto;
  }
    .--lcars .weekly-day strong {
      padding: 0;
    }
    .--lcars .weekly-day-temperature {
      position: relative;
      display: inline-flex;
      margin: 0;
    }
    .--lcars .weekly-day-temperature:last-child {
      margin-left: 1em;
      padding-left: 1em;
    }
    .--lcars .weekly-day-temperature:last-child:before {
      content: '';
      position: absolute;
      top: 1em;
      left: -0.2em;
      height: 0.25em;
      width: 0.33em;
      vertical-align: middle;
      border-radius: 50%;
      background-color: var(--color-background);
    }

      .--lcars .weekly-day-temperature > span:first-child {
        width: auto;
      }
      .--lcars .weekly-day-temperature > span:last-child {
        margin-left: 0.25em;
      }
    .--lcars .weekly-day .temp-box {
      padding-left: 3em;
      text-align: right;
    }

@media (max-width: 690px) {
  .weekly-days {
    grid-template-columns: repeat(2, 1fr);
  }
}
