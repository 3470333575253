.hourly {
  padding-bottom: 1em;
  font-size: 1rem;
  overflow: auto;
}

  .hourly-hours {
    display: flex;
  }

    .hourly-hour {
      margin-right: 1.8em;
    }

      .hourly-hour-time {
        display: block;
        margin-top: 0.5em;
        text-transform: uppercase;
      }

      .hourly-hour-temp {
        margin-top: 0.5em;
        white-space: nowrap;
      }

        .hourly-hour-temp span {
          display: inline-block;
        }
        .hourly-hour-temp span:not(:first-child) {
          margin-left: 0.5em;
          padding-left: 0.5em;
          border-left: 1px solid var(--color-grey-700);
        }

      .hourly-hour-icon {
        margin-top: 0.5em;
      }
        .hourly-hour-icon svg {
          font-size: 1.5em;
        }


.--lcars .hourly-hour {
  margin-right: 1.25em;
}
  .--lcars .temp-box span {
    font-size: 1.5em;
  }
  .--lcars .hourly-hour-temp {
    margin-top: 0;
  }
  .--lcars .hourly-hour-temp span:not(:first-child) {
    margin-left: 0.25em;
  }

.--lcars .temp-box > .hourly-hour-icon {
  position: absolute;
  bottom: calc(100% + 0.33em);
  right: 1em;
  color: #f5f6fa;
}
  .--lcars .temp-box > .hourly-hour-icon svg {
    font-size: 0.8em;
  }
