.notification {
  font-size: 0.9rem;
  padding: 1em;
  border: 2px solid var(--color-grey);
  border-radius: 0.5em;
}

.notification.--error {
  color: var(--color-red);
  border-color: var(--color-red);
}

.notification.--warning {
  color: var(--color-yellow);
  border-color: var(--color-yellow);
}
